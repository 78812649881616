<template>
<div class="backBox" >
<el-main ref="elmaincards"  style="justify-content: flex-start;display: flex;flex-direction:column;overflow-y: auto;"
      >
               
  <el-collapse accordion v-model="activeNames"  style="width:100%;min-width:350px">
  <el-collapse-item name="abc1" style1="max-height:300px"><div slot="title" style="margin-left:20px"><i class="header-icon el-icon-setting" 
          style="margin-right:10px;"></i>权限设置</div>
    <div class="flex_row" style="max-width:600px;">
      <div class="flex_col" style="width:50%;height:400px;">
        <div class="flex_row" style="margin:15px 0;width:100%;">
          <el-button type="success"  :size="size"  class="button" plain :loading="checkerroring" style="width:150px;"
                  @click="CkeckError">查错</el-button>
          <el-input :size="size" v-model="Mobile" v-if="isPc"
            :maxlength="11" clearable @keyup.enter.native="EnterFun($event)" style="margin:0 10px;">
          </el-input>
          <el-button type="primary"  :size="size"  class="button" 
                  @click="SaveAuthority">保存</el-button>
        </div>
        <el-table height="500" :size="size" ref="operatorList" :data="operatorList" highlight-current-row 
				@current-change="handleCurrentChange" tooltip-effect="dark" style="width: 100%;" :key="refrsh"
				border  :header-cell-style="tableHeaderColor" >
					<div slot="empty" >暂无数据</div>
					<el-table-column prop="StaffName" label="姓名" width="70" align="center" show-overflow-tooltip>
          </el-table-column>
					<el-table-column prop="Mobile" label="手机号"  min-width="60"  show-overflow-tooltip
            style1="flex-grow:1;">   
          </el-table-column>
        </el-table>
      </div>
      <div class="flex_col" style="margin-left:15px;width:40%;flex-grow:1;height:400px;">
        
        <el-table height="500" :size="size" ref="authorityList" :data="authorityList" highlight-current-row 
				style="width: 100%;margin-top:15px;" :key="refrsh"
				@selection-change="selection_change" border  :header-cell-style="tableHeaderColor" >
					
					<el-table-column align="center"
            type="selection"
            width="45">
          </el-table-column>
					<el-table-column prop="QX2" label="权限项目"  min-width="60"  show-overflow-tooltip
            style1="flex-grow:1;">   
          </el-table-column>
        </el-table>
      </div>
    </div>
  </el-collapse-item>
  <el-collapse-item name="abc"><div slot="title" style="margin-left:20px"><i class="header-icon el-icon-price-tag" 
          style="margin-right:10px;"></i>燃气价格</div>
      <div class="flex_col" style="width:100%;max-width:600px;background1:#ee33ff;">
        <div class="flex_row" style="margin:15px 0;width:100%;background1:#eeeeff;">
          <div style="font-size:14px;margin-right:10px;">开始日期</div>
          <el-date-picker
            v-model="cdate" :size="size" format="yyyy-MM-dd"  value-format="yyyy-MM-dd"
            type="date" 
            placeholder="选择日期">
          </el-date-picker><div style="flex-grow:1;"></div>
                <el-button type="primary" :size="size"  class="button" 
                  @click="SaveGasPrice">保 存</el-button>
          
        </div>
      <div v-for="item in Gaslist" :key="item.m_mpNo" style="width:100%;">
      <el-card style="margin-bottom:15px;background: #f9ffff;" >
        <div class="flex_row" style="width:100%;min-width:250px;">
          <el-image  style="width:100px;height: 60px;"
            :src="item.imageurl"
            fit="scale-down"></el-image>
        <div class="flex_col" style="align-items: flex-start;
            width:100%;min-width:200px;">
            <div style="font-size: 14px;">气种：{{item.yylx}}</div>
            
            <div class="flex_row" style="width:100%;margin-top:5px;">
              <div>价格：</div>
              <div style="color:#f00">￥{{item.m_price}}</div>
              <div style="flex-grow:1;"></div>
              <el-input-number v-model="item.sl" :min="60" :max="800" size="medium" style="width:140px;"></el-input-number>
            </div>
        </div>
        </div>
      </el-card>
      
    </div>
        </div>
  </el-collapse-item>
</el-collapse>
</el-main>
</div>  
</template>

<script>
export default {
  data() {
    return {
      size: 'medium',//medium,small,mini,
      isPc:false,
      checkerroring:false,
      activeNames:"abc",
      Mobile:"",
      tableHeight:500,
      operatorList:[],//操作员列表
      authorityList:[],//权限列表
      selectOperator:{},
      Selectioned: [],
      cdate:"",//修改数据库的日期
      Gaslist:[],//气种
      refrsh:false,
    }
  },
  watch:{

  },
  computed: {
    
  },
  mounted(){
    this.cdate=this.$api.formatDate(new Date());
    this.isPc=this.$store.getters.GettabBarInfo("isPc");
    this.getOperatorList()
  },
  methods: {
    CkeckError(){
      this.checkerroring=true;
      let data = {
        procext:"order",
        lx: "ckeckerror"
      };
      this.$api.post("",data,
        (res) => {
          this.$message.success(res["message"]);
          this.checkerroring=false;
        },
        (failure) => {
          this.$message.error(failure);
        }
      );
    },
    selection_change(e){
      console.log("selection_change")
      this.Selectioned=e;
    },
    handleCurrentChange(currentRow){
      
      this.selectOperator=currentRow;
      if (currentRow===null) return
      this.$refs.authorityList.clearSelection();
      console.log("selection_change",this.Selectioned)
      this.getOperator(currentRow.Mobile)
    },
    getOperator(mobile){
      let data = {
        lx: "getoperator",
        sqlone:mobile,
        procext:"order"
      };
      this.$api.post("",data,
        (res) => {
          if (res["status"] == "1") {
            
            this.authorityList.forEach(q=>{
              let idx= res.records.findIndex(p=>p.QX2==q.QX2)
              if (idx>-1)
                this.$refs.authorityList.toggleRowSelection(q);
            })
          }
          if (res["status"] == "-2") {
            this.$parent.$parent.$parent.swapToken();
          }
        },
        (failure) => {}
      );
    },
    SaveAuthority(){
      let sel=this.selectOperator
      if (!(sel.Mobile)) {
        this.$message.error("先选择一个账号");
        return
      }
      let sqltwo="";
      this.Selectioned.forEach(e=>{
        sqltwo+=e.QX2+","
      })
      this.$confirm("真的要保存【"+sel.StaffName+sel.Mobile+"】的操作权限吗?", "确认操作", {
          confirmButtonText: "确定",cancelButtonText: "取消",
          type: "info",iconClass: "el-icon-question",
        })
        .then(() => {
          let data = {
            procext:"order",
            lx: "saveauthority",
            sqlone:sel.Mobile,
            sqltwo:sqltwo
          };
          this.$api.post("",data,
            (res) => {
              if (res["status"] == "1") {
                let js=res["js"];
                if (js>0){
                  this.$message.success(res["message"]);
                  return
                }
              }
              this.$message.error(res["message"]);
              if (res["status"] == "-2") {
                this.$parent.$parent.$parent.swapToken();
              }
            },
            (failure) => {
              //this.showUserInfo = false;
              this.$message.error(failure);
            }
          );
        })
      .catch(() => {});
    },
    SaveGasPrice(){
      this.$confirm("真的要保存"+this.cdate+"的燃气价格吗?", "确认操作", {
          confirmButtonText: "确定",cancelButtonText: "取消",
          type: "info",iconClass: "el-icon-question",
        })
        .then(() => {
          let data = {
            procext:"order",
            lx: "savegasprice",
            sqlone:this.cdate,
            sqltwo:JSON.stringify(this.Gaslist)
          };
          this.$api.post("",data,
            (res) => {
              this.$message.success(res["message"]);
              if (res["status"] == "1") {
                this.Gaslist=res.records;
              }
              if (res["status"] == "-2") {
                this.$parent.$parent.$parent.swapToken();
              }
            },
            (failure) => {
              //this.showUserInfo = false;
              this.$message.error(failure);
            }
          );
        })
      .catch(() => {});
    },
    getOperatorList(){
      let data = {
        lx: "getoperatorlist",
        procext:"order"
      };
      this.$api.post("",data,
        (res) => {
          if (res["status"] == "1") {
            this.operatorList=res.records;
            this.authorityList=res.recordc;
            this.Gaslist=res.recordd;
          }
          if (res["status"] == "-2") {
            this.$parent.$parent.$parent.swapToken();
          }
        },
        (failure) => {}
      );
    },
    EnterFun(e){

    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #f0f0f0;'
        }
    },
  },
  created() {
  },
};
</script>
<!--公共样式(或者是width: 0;)-->
<style src = "../../Styles/cover.css"></style>
<style src = "../../Styles/common.css"></style>
<style src = "../../Styles/iconfont/iconfont.css"></style>

<style scoped>
.myscrollbar::-webkit-scrollbar{
display:none;
}
.backBox {
  width: 100%;
  height: 100%;

}
.flex_row{
    display: flex;             /*设置为flex布局*/
    justify-content: space-between;   /*水平居中*/
    align-items: center;       /*垂直居中*/
    flex-direction: row;
}
.flex_col{
    display: flex;             /*设置为flex布局*/
    justify-content: flex-start;   /*水平居中*/
    align-items: center;       /*垂直居中*/
    flex-direction: column;
}


</style>